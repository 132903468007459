<template>
  <div class="reset">
    <div class="reset_left">
      <Form ref="formInline" :model="formInline" :rules="ruleInline">
        <FormItem class="reset_title">
          <span class="fs30">重置登录密码</span>
        </FormItem>
        <FormItem label="新密码" prop="password">
          <i-input type="password"  size="large" v-model="formInline.password" placeholder="请填写新密码"></i-input>
        </FormItem>

        <FormItem label="重复新密码" prop="repassword">
          <i-input type="password" size="large" v-model="formInline.repassword" placeholder="请重复填写新密码"></i-input>
        </FormItem>

        <FormItem>
          <i-button type="primary" size="large" @click="handleSubmit('formInline')" class="submit-button">重置密码</i-button>
        </FormItem>
      </Form>
    </div>
  </div>

</template>

<script>
    import '@/css/common.css';

    export default {
        name: "ResetPassword",
        data() {
            return {
                formInline: {
                    repassword: '',
                    password: '',
                },
                ruleInline: {
                    password: [
                        {required: true, message: '请填写密码', trigger: 'blur'},
                        {type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur'}
                    ],
                    repassword: [
                        {required: true, message: '请重复填写密码', trigger: 'blur'},
                        {type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur',}
                    ]
                }
            }
        },
        methods: {
            handleSubmit(name) {
                let param = {
                    mobile: this.$route.query.mobile,
                    code: this.$route.query.code,
                    password: this.md5(this.formInline.password),
                    repassword: this.md5(this.formInline.repassword),
                };

                this.$refs[name].validate((valid) => {
                    if (valid) {
                        if (param.password == param.repassword) {
                            this.$axios
                                .post('/api/user/resetPassword', param)
                                .then((response) => {
                                    if (response.data.err_no == 0) {
                                        this.$router.push({name: 'login'});
                                    } else if (response.data.code == '1001') {
                                        //1001表示验证码过期
                                        this.$Message.error(response.data.err_msg);
                                        this.timer = setTimeout(() => {
                                            this.$router.push({name: 'forgot'});
                                        }, 2000);
                                    }
                                    else {
                                        this.$Message.error(response.data.err_msg);
                                    }
                                })
                        } else {
                            this.$Message.warning('两次密码不一致，请检查');
                        }
                    }
                })
            },
            checkEmailCode() {
                this.axios
                    .post('/api/user/isEmailCodeValid', this.$route.query)
                    .then((response) => {
                        if (response.data.code == '200') {
                            return;
                        } else {
                            this.$Message.error(response.data.msg);
                            this.$router.push({name: 'index'});
                        }
                    })
            }
        },
        mounted() {
            this.checkEmailCode()
        }
    }
</script>

<style scoped>
    .reset_left {
        float: left;
        width: 400px;
        margin: 80px 100px;
        height: auto;
    }

    .submit-button {
        width: 200px;
        float: left;
    }

    .reset_title {
        text-align: left;
        color: #657180;
    }

    .reset, .reset a {
        color: #657180;
    }

    .ivu-form .reset_title .ivu-form-item-label {
        font-size: 20px !important;
    }

    .ivu-form-item {
        margin-bottom: 40px;
    }
</style>
